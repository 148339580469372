:root {
  /* --primary: #ffffff; */
  --secondary: #AB0000;
  --tertiary: #ffffff;
  --light: #fafafa;
  --dark: #272f32;
  --error: rgb(228, 46, 1);
  --spacing-one: calc(1rem * 0.5);
  --spacing-two: calc(1rem);
  --href: #5b0000;
}

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Karla', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  color: var(--dark);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  margin: 5px 0;
  line-height: 1.25;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1.1rem;
  margin-top: 0;
}

a {
  color: var(--href);
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

header {
  background-color: #a71d31;
  background-image: linear-gradient(315deg, #a71d31 0%, #3f0d12 74%);  
}

header a {
  color: var(--light);
}

header h1 {
  color: var(--light);
}

button {
  font-size: 1rem;
  text-decoration: none;
  /* padding: 0.25rem 1rem; */
  padding: 6px 22px;
  border-radius: .5rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 1px;
  background-color: var(--secondary);
  color:white;
  transition: 100ms;
}

button:hover {
  opacity: .875;
  transform: scale(1.05);
}

button[disabled] {
  cursor: default;
  opacity: .0;
}

img {
  max-width: 100%;
}

span[role="img"] {
  cursor: default;
}

form {
  width: 40%;
}

input {
  font-size: 1rem;
  padding: 0.25rem;
  border: 1px solid var(--dark);
  outline: none;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

input:focus {
  border: 1px solid var(--secondary);
  box-shadow: 0 0 .25rem rgba(0, 0, 0, .5);
}

.container {
  /* Page Width dimensions*/
  /* max-width: 80%; */
  margin-left: auto;
  margin-right: auto;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-row {
  display: flex;
  flex-wrap: wrap;
  /* max-width: 1200px; */
  /* max-width: 80%; */
}
.headerNav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.headerNav h1 {
  color: var(--light)
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

.my-1 {
  margin-top: var(--spacing-one);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: var(--spacing-two);
  margin-bottom: var(--spacing-two);
  /* Makes Shoe container max out at 1200px width without effecting background styling. */
  max-width: 1200px;
}

.px-1 {
  padding-right: var(--spacing-one);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-two);
}

.py-1 {
  padding-top: var(--spacing-one);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: var(--spacing-two);
  padding-bottom: var(--spacing-two);
}

.card:hover {
  transform: scale(1.01);
}
.card {
  /* width: 25%; */
  text-align: center;
  flex: 1 1 20%;
  margin: 8px;
  background-color: var(--tertiary);
  box-shadow: 0 1px 1px rgba(0,0,0,0.11), 
              0 2px 2px rgba(0,0,0,0.11), 
              0 4px 4px rgba(0,0,0,0.11), 
              0 6px 8px rgba(0,0,0,0.11),
              0 8px 16px rgba(0,0,0,0.11);
-webkit-border-radius: 15px;
-moz-border-radius: 15px;
border-radius: 15px;
transition: 150ms;
border-top: 1px solid rgb(228, 228, 228);
}
.card p{
  color: black;
  margin-bottom: 5px;
}
.card a{
  display: hidden;
}
.card img {
  -webkit-border-radius: 15px;
-moz-border-radius: 15px;
border-radius: 15px;
padding: 4px;
padding-top: 10px;
height: 300px;
width: 300px;
object-fit: cover;
}
.card button {
  margin: 10px;
}
.productPage img,h2,p {
  margin-bottom: 30px;
  color: black;
}
.productPage h2 {
  font-weight: 550;
}
.headerLinks {
  display: flex;
}
.categoryBtns button {
  margin: 0px 4px 4px 4px;
}
.headerImgDiv {
  margin-bottom: 20px !important;
    }
.error-text {
  color: var(--error);
}
.productPage{
  background-color: rgb(247, 247, 247);
}
.detailPage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  max-width: 80%;
  Padding: 4%;
  background-image: url(https://www.transparenttextures.com/patterns/asfalt-light.png);
  background-repeat: repeat;

}
.detailRight {
  display:flex;
  flex-direction: column;
  position: relative;
  width: 40vw;
}
.detailLeft {
height: 100%;
width: 100%;
display: flex;
justify-content: flex-end;
margin-right: 2%;
width: 40vw;
}
.detailPrice{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}
.detailBtns{
  display: flex;
  flex-direction: column;
  width: 250px;
  margin-top: 20px;
}
@media screen and (max-width: 1440px) {
  .homeHeaderText {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
.card{
  flex: 1 1 30%;
}
  .card img {
  width: 100% !important;
}
}

@media screen and (max-width: 768px) {
  .flex-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
  }
  .container {
    align-content: center;
  }
  .categoryBtns {
    display: flex;
    justify-content: center;
  }
  .card {
    flex: 1 1 33%;
  }
  .headerImgDiv {
height: 30vh !important;
margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 420px
) {
  .headerNav {
    justify-content: center;
    flex-direction: column;
    margin: 0px;
    padding-top: 10px;
  }
  .categoryBtns {
    flex-direction: column;
  }
  .categoryBtns button {
    margin: 5px;
    width: 120px;
  }
  .headerNav h1 {
    font-size:xx-large;
  }
  .headerLinks {
    display: inline;
    flex-direction: column;
  } 
  .headerLinks div {
    /* margin: 0px !important; */
    display: flex;
    flex-direction: column;
  }
  .headerNav ul li {
  }
  .cart-closed {
    margin: 0px !important;
    padding: 0px !important;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .cart-closed span {
    text-align: center;
  }
  .flex-row .card {
transform: scale(.9);
  }

  .detailPage {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .detailLeft {
    width: auto;
  }
  .detailRight {
    width: auto;
  }
  .detailPrice {
    text-align: center;
  }
}



body{
    width: 100%;
    text-align: center;
}
  
h4{
  font-family: 'Segoe UI', bold;
  font-size: 35px;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

form{
  display: inline-block;
  align-items: center;
  height: 270px;
  width: 350px;
  margin-bottom: 170px;
}

.text_area{
  align-content: center;
  border-radius: 22px;
  margin-bottom: 35px;
  width: 100%;
  height: 64px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2), 0 0 20px 0 rgba(0, 0, 0, 0.19);
}

.text_input{
  margin-top: 1.2rem;
  font-family: 'Montserrat regular';
  font-size: 20px;
  border: none;
  width: 85%;
}

.btn{
  margin-top: 1px;
  width: 165px;
  height: 60px;
  border-radius: 30px;
  background: #ca2121;
  border: none;
  font-family: 'Montserrat';
  font-size: 20px;
  color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgb(228, 46, 1), 0 6px 20px 0 rgb(228, 46, 1);
  cursor: pointer;
}

.login{
  display: inline-block;
  background: #FFFFFF;
  width: 434px;
  height: 500px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 22px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.signup{
  display: inline-block;
  background: #FFFFFF;
  width: 434px;
  height: 700px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 22px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.login .link {
  margin-top: -35%;
}

.link{
  color: #bb1c07;
  font-family: 'Montserrat light';
  margin-top: 10%;
  font-size: 18px;
  cursor: pointer;
  display: block;
  text-decoration: none;
}